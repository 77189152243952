<div class="w-full py-12 flex items-center justify-center bg-black">
    <div class="fhd:w-2/3 w-full px-4 text-white flex flex-col">
        <div class="w-full text-7xl font-bold">
            <!--h1 class="w-full fhd:w-2/3">How can we help you. get
                in touch</h1-->
            <img class="h-20 mx-auto hd:mx-0" src="assets/images/logo.png">
        </div>
        <div class="flex mt-8 flex-col fhd:flex-row fhd:justify-between">
            <p class="w-full text-center hd:text-left fhd:w-2/3 text-2xl text-white">See if Coded In Cloud is right for
                you. (It totally is.)</p>
            <!--div class="w-44 pt-6 mx-auto hd:mx-0 fhd:pt-0">
                <app-button [text]="'See plans'"
                    classes="font-poppins-semibold rounded px-10 text-black py-4 bg-orange hd:text-2xl hd:py-4 hd:px-10 2k:text-4xl 2k:py-4 2k:px-10 shadow-[0px_10px_1px_rgba(166,_115,_17,_1)]"></app-button>

            </div-->
        </div>
        <div class="flex flex-col">
            <div class="flex mt-24 mb-12 flex-row justify-between">
                <!--a
                    class="hidden fhd:block cursor-pointer font-poppins-regulars text-white hover:text-white uppercase">About</a>
                <a
                    class="hidden fhd:block cursor-pointer font-poppins-regulars text-white hover:text-white uppercase">Services</a>
                <a class="hidden fhd:block cursor-pointer font-poppins-regulars text-white hover:text-white uppercase">Why
                    us</a>
                <a
                    class="hidden fhd:block cursor-pointer font-poppins-regulars text-white hover:text-white uppercase">Contact</a-->
                <div class="flex flex-row space-x-8 items-center justify-between">
                    <!--a>
                        <img class="w-8" src="assets/icons/social/facebook.svg" alt="">
                    </a-->
                    <a href="https://www.linkedin.com/company/coded-in-cloud/about/?viewAsMember=true">
                        <img class="w-8" src="assets/icons/social/linkedin.svg" alt="">
                    </a>
                </div>
            </div>
            <hr class="border-white" />
            <p class="w-full text-center my-12 text-white">Copyright © 2024 Coded In Cloud</p>
        </div>
    </div>
</div>